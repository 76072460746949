<template>
    <metainfo></metainfo>
    <router-view />
</template>
<script setup>
import '@/assets/css/baase.less';
import '@/assets/css/common.less';
import '@/assets/css/price.less';
import '@/assets/css/product.less';
import '@/assets/css/country.less';
import '@/assets/css/animation.css';
import Btnjs from '@/assets/plugins/button.js';
import chinese from '@/assets/json/chinese.js';
import english from '@/assets/json/english.js';
import MataChinsese from '@/assets/json/chineseMeta.js';
import MetaEnglish from '@/assets/json/englishMeta.js';
import { provide, onMounted, watch, nextTick } from 'vue';
import { useMeta } from 'vue-meta';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';

let route = useRoute();
let router = useRouter();


// 使用示例

let lang = localStorage.getItem('langrage');
let btnEvent = new Btnjs();
provide('btnEvent', btnEvent);
if (lang === 'english') {
    provide('langrage', english);
} else {
    provide('langrage', chinese);
}
onMounted(() => {
    document.dispatchEvent(new Event('render-event'));
    
});

// 解决el-table ResizeObserver loop completed with undelivered notifications.报错
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};
</script>
